.price-holder-info-container {
	padding: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -30px;
	width: 100%;
	max-width: 800px;
}

.price-holder-tile {
	color: #d8d8d8;
	border-radius: 40px; 
	padding: 30px;
	width: 100%;
	max-width: 800px;
}

.price-holder-list-bubbles {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.price-holder-list-item {
	background-color: rgba(58, 58, 58, 0.2);
    border: 3px solid rgb(0, 0, 0);
	border-radius: 10px;
	padding: 15px;
	font-size: 1.2em;
	font-family: "Share Tech Mono";
	text-align: center;
	color: #d8d8d8;
	transition: background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease,
		text-shadow 0.3s ease;
}

.price-holder-list-item:hover {
	/* background-color: rgba(255, 97, 97, 0.3);
	transform: scale(1.05);
	border-color: #fb6161;
	text-shadow: 0 0 5px #fb6161, 0 0 10px #fb6161; */
    color: rgb(130, 76, 222);
    transform: scale(1.05);
	background-color: rgba(18, 18, 18, 0.3);
    /* background-color: rgb(205, 177, 40); */
    /* border-color: #fb6161; */
    /* text-shadow: 0 0 5px #aa79ff, 0 0 10px #aa79ff; */
}
.price-holder-list-item::placeholder {
	color: white;
  }
  
.price-holder-conversion {
	background-color: rgba(255, 255, 255, 0.1);
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-radius: 10px;
	padding: 15px;
	font-size: 1em;
	font-family: "Share Tech Mono";
	text-align: center;
	color: #d8d8d8;
	transition: background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease,
		text-shadow 0.3s ease;
}
@media only screen and (max-width: 1460px) {
	.price-holder-list-bubbles {
		grid-template-columns: repeat(2, 1fr);
	}
	.price-holder-converted {
		grid-column: span 2;
		justify-self: center;
	}
}
@media only screen and (max-width: 768px) {
	.price-holder-info-container {
		width: 90%;
		margin-top: -10px;
		margin-bottom: 0px;
	}
	.price-holder-list-bubbles {
		grid-template-columns: repeat(2, 1fr);
	}

	.price-holder-tile {
		width: 90%;
		padding: 20px;
	}

	.price-holder-list-item {
		font-size: 0.7em;
		padding: 10px;
		width: 90%;
	}
	.price-holder-converted {
		font-size: 0.7em;
		grid-column: span 2;
		justify-self: center;
	}
}

@keyframes textGlow {
	0% {
		text-shadow: 0 0 5px #fb6161, 0 0 10px #fb6161;
	}
	100% {
		text-shadow: 0 0 10px #fb6161, 0 0 20px #fb6161;
	}
}
