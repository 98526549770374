.dex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 40dvw;
  }
  .ca-text {
    cursor: pointer;
    color: #ffffff;
    transition: color 0.3s ease, text-shadow 0.3s ease;
    font-size: 1.7em;
    font-weight: 900;
    border: 3px solid rgba(0, 0, 0, 0.732);
    border-radius: 20px;
    padding: 10px;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
  }
  
  .ca-text:hover {
    color: #aa79ff;
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.5);
  }
  .dex-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .dex-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    line-height: 1.8;
    color: #d8d8d8;
    padding: 15px 30px;
    background-color: rgba(58, 58, 58, 0.2);
    border: 3px solid rgb(0, 0, 0);
    border-radius: 40px;
    text-decoration: none;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    text-align: center;
    width: 48%;
    height: 80px;
    box-sizing: border-box;
  }
  
  .dex-button:hover {
    color: rgb(130, 76, 222);
    transform: scale(1.05);
	background-color: rgba(18, 18, 18, 0.3);
  }
  .icon {
    height: 24px; 
    margin-right: 10px;
  }
  .ca-text-dex {
    display: none;
}
.ca-text-dex:hover {
  color: #aa79ff;
  transform: scale(1.05);
  background-color: rgba(0, 0, 0, 0.5);
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove arrows in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.number-input-wrapper {
  position: relative;
  width: 100%;
  display: inline-block;
}


  @media only screen and (max-width: 768px) {
    .dex-container {
        width: 100%;
    }

    .dex-row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dex-button {
        width: 100%;
        height: 60px;
        margin-bottom: 10px;
    }
    .ca-text-dex {
      display: block; 
      cursor: pointer;
      color: #ffffff;
      transition: color 0.3s ease, text-shadow 0.3s ease;
      font-size: .85em;
      border: 3px solid rgba(0, 0, 0, 0.732);
      border-radius: 20px;
      padding: 10px;
      transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;
  }
  .price-holder-converted {
    font-size: 0.7em;
    grid-column: span 2;
    justify-self: center;
  }
}
