.footer {
  padding-top: 100px;
  color: #644893;
  text-align: center;
  margin-top: auto;
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.7);
  /* background-color: #7d0303; */
  width: 100vw;
  /* background-image: linear-gradient(135deg, #7d0303, #000000, #7d0303); */
}

.social-links {
  list-style: none;
  display: flex;
  justify-content: center;
}

.social-links li {
  margin: 0 25px;
}

.footer-icon {
  height: 20px; 
  margin-top: 25px;
}

.footer-icon-dextools {
  height: 30px;
  margin-top: 20px;
}

.footer-icon-telegram {
  height: 35px;
  margin-top: 16px;
}
.footer-icon-telegram {
  height: 35px;
  margin-top: 16px;
}

@media only screen and (max-width: 768px) {
  .social-links {
    margin-right: 25px;
  }
  .footer-icon-coingecko {
    height: 34px;
    margin-top: 16px;
  }
  .footer-icon-holderscan {
    height: 35px;
    margin-top: 16px;
  }
  .social-links li {
    margin: 0 15px;
  }
}