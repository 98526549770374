.ticker {
    overflow: hidden;
    white-space: nowrap;
    color: #aa79ff;
    font-size: 1.3em;
    /* padding-bottom: 40px; */
    user-select: none;
    width: 100vw;
    z-index: 1500;
    margin-top: -10px;
    font-weight: 900;
    /* margin-bottom: -20px; */
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    position:relative;
  }
  
  @keyframes move {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-50%, 0, 0);
    }
}
  
.ticker-move {
  display: flex;
  width: max-content;
  animation: move 360s linear infinite;
  will-change: transform;
}
  
  .ticker-item {
    display: inline-block;
    padding-right: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .ticker {
      margin-top: 25px;
      padding-top: 10px;
      padding-bottom: 20px;
      font-size: 1em;
      color: #aa79ff;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }