.meme-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    padding-top: 225px;
    padding-bottom: 200px;
    margin-left: -200px;
    width: calc(100vw + 200px);
    box-sizing: border-box;
    min-height: calc(100vh);
    background-image: url('../../public/images/black_graybgdarker.png');
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    overflow: hidden;
}
.meme-header {
	font-size: 2.3em;
	color: #aa79ff;
	animation: textGlow 2s infinite alternate;
	position: absolute;
	top: 0;
	left: 48%;
	padding: 10px 15px;
	font-family: "Share Tech Mono", sans-serif;
	z-index: 1100;
}
.primary-buttons-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	user-select: none;
}
.additional-buttons {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: -30px;
	user-select: none;
}
.canvas-toolbar-container {
	display: flex;
	align-items: start;
}
.canvas-frame {
	margin: 0 auto;
	position: relative;
	border: 5px solid #c5b250;
	background-color: black;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(210, 99, 99, 0.1);
	width: 600px;
	height: 600px;
}
.bottom-frame-buttons {
	position: absolute;
	bottom: -55px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 10px;
	justify-content: center;
	width: 590px;
	user-select: none;
}
.button-toolbar {
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-right: 20px;
}

.primary-button {
    background-image: linear-gradient(155deg, #aa79ff -15%, #cdb128 50%, #aa79ff 130%);
	color: #ffffff;
	border: 2px solid #aa79ff;
	padding: 10px 15px;
	border-radius: 10px;
	cursor: pointer;
	font-size: 18px;
	margin: 0;
	transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
	font-family: "Share Tech Mono", sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 185px;
	height: 45px;
	user-select: none;
	box-shadow: 0 4px 15px rgba(205, 177, 40, 0.6);
}

.primary-button:hover {
	background: linear-gradient(145deg,#aa79ff, #cdb128, #aa79ff);
	transform: scale(1.05);
	box-shadow: 0 6px 20px rgb(205, 177, 40, 0.8);
}


.modal-content {
	background: linear-gradient(135deg, #aa79ff, #cdb128, #aa79ff);
	border: 2px solid #000000;
	border-radius: 20px;
	overflow-y: auto;
	box-shadow: 0 8px 20px rgba(205, 177, 40, 0.4), 0 12px 30px rgba(205, 177, 40 0.3);	
	position: absolute;
	bottom: -115px;
	left: 730px;
	transform: translateX(-50%);
	width: 220px;
	height: 720px;
	max-height: 720px;
	display: flex;
	flex-direction: column;
	align-items: center;
	user-select: none;
  color: #ffffff;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.modal-content:hover {
	box-shadow: 0 10px 25px rgba(205, 177, 40, 0.6), 0 14px 35px rgba(0, 0, 0, 0.4);
}
.modal-content::-webkit-scrollbar {
	width: 10px;
}

.modal-content::-webkit-scrollbar-track {
	background: linear-gradient(145deg, #3a3a3a, #636363);
	border-radius: 20px;
}

.modal-content::-webkit-scrollbar-thumb {
	background-color: #cdb128;
	border-radius: 20px;
	border: 2px solid #4a4a4a;
}

.modal-content::-webkit-scrollbar-thumb:hover {
	background-color: #cdb128;
}
.search-text {
	position: absolute;
	top: 188px;
	left: 49%;
	padding: 10px 15px;
	border-radius: 10px;
	border: 1px solid #ccc;
	background: linear-gradient(145deg, #ffffff, #f1f1f1);
	color: #000;
	font-family: "Share Tech Mono", sans-serif;
	font-size: 18px;
	box-shadow: 0px 4px 15px rgba(205, 177, 40, 0.5);
	z-index: 1100;
	transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.search-text:focus,
.search-text:hover {
	box-shadow: 0px 6px 20px rgba(205, 177, 40, 0.7);
	transform: scale(1.05);
}
.image-selection {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: auto;
	padding-top: 10px;
}

/* Style for individual images */
.image-selection img {
	width: 100px;
	height: auto;
	cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 1024px) {
	.App {
		background-attachment: fixed;
		background-size: contain;
		background-position: center;
		background-repeat: repeat;
	}

	.meme-container {
		padding: 20px;
		padding-top: 100px;
		margin-left: 0px;
		width: calc(100vw + 200px);
		box-sizing: border-box;
		min-height: calc(100vh);
	}
	.canvas-toolbar-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.canvas-frame {
		margin: 0 auto;
		position: relative;
		border: 5px solid #c5b250;
		background-color: black;
		border-radius: 5px;
		box-shadow: 0 4px 8px rgba(210, 99, 99, 0.1);
		width: 97.5%;
		max-width: 1000px;
		height: auto;
	}
	.bottom-frame-buttons,
	.modal-mobile {
		flex-basis: 33%;
		display: flex;
		bottom: -365px;
		flex-direction: column;
		align-items: center;
		margin-left: -10px;
		padding: 0; 
	}
  
	.primary-buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		z-index: 1000;
	}
  
	.hide-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
	}
   .primary-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .additional-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

	.button-toolbar {
		display: grid;
		grid-template-columns: 1fr 1fr;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		margin-top: 20px;
		margin-left: -80px;
	}
	.bottom-and-modal {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.color-input {
		z-index: 1500;
	}
	.primary-button {
		color: #ffffff;
		padding: 10px 15px;
		cursor: pointer;
		margin: 0;
		transition: background-color 0.3s ease;
		font-family: "Share Tech Mono", sans-serif;
		font-size: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 40px;
	}
	.meme-header {
		font-size: 1.7em;
		left: 32%;
	}
	.search-text-mobile {
		position: absolute;
		bottom: 339px;
		left: 190px;
		width: 105px;
		padding: 8px;
		border-radius: 5px;
		color: #000;
		font-family: "Share Tech Mono", sans-serif;
		font-size: 13px;
	}
	.modal-mobile {
		position: absolute;
		bottom: -398px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		padding-left: 280px;
	}
	.modal-content {
		overflow-y: auto;
		position: absolute;
		left: calc(90%);
		top: -330px;
		width: 125px;
		max-height: 343px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 15px;
		position: absolute;
	}
	.image-selection {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow-x: auto;
		padding-top: 10px;
	}

	.image-selection img {
		width: 100px;
		height: auto;
		cursor: pointer;
	}
}
