@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Orbitron:wght@400..900&family=Share+Tech+Mono&display=swap');

html,
body {
    width: 100dvw;
    overflow-x: hidden;
    min-height: 100dvh;
    margin: 0;
    padding: 0;
}

.App {
	font-family: "Share Tech Mono";
	margin: 0;
	padding: 0;
	height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    /* background-color: #978320; */
    background-image: linear-gradient(155deg, #aa79ff -5%, #cdb128 50%, #aa79ff 130%);
    /* background-color: #7d0303; */
    /* background-image: linear-gradient(135deg, #7d0303, #000000, #7d0303); */
    text-align: center;
	min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .App {
        background-image: linear-gradient(105deg, #cdb128 , #aa79ff, #cdb128);


    }
}