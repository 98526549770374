/* Base styles */
body {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.statement-text {
	font-size: 1.5em;
	line-height: 1.8;
	color: #d8d8d8;
	padding: 15px;
	margin-bottom: 10px;
	transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out;
}

.statement-text:hover {
	color: #ffffff;
	transform: scale(1.05);
	text-shadow: 0 0 5px #d0aa0e, 0 0 10px #d0aa0e;
}
.info-container {
	padding: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 70px;
	align-items: flex-start;
	margin-bottom: 100px;
}

.info-container > section {
	flex: 1;
}
.tile {
	flex-grow: 1;
	flex-basis: 0;
	padding: 50px;
	text-align: center;
	background: linear-gradient(#1a1919, #393636);
	color: white;
	border-radius: 100px;
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.7);
	box-sizing: border-box;
	min-height: 520px;
	width: 40dvw;
}

.tile h1 {
	font-size: 2.5em;
	color: #aa79ff;
	animation: textGlow 2s infinite alternate;
}

.list-bubbles {
	list-style-type: none;
	padding: 0;
}
.list-bubbles li {
	font-size: 1.5em;
	line-height: 1.8;
	color: #d8d8d8;
	padding: 15px;
	margin-bottom: 10px;
	background-color: rgba(255, 255, 255, 0.1);
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-radius: 40px;
	transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out;
}

.list-bubbles li:hover {
	color: #cdb128;
	transform: scale(1.05);
}

/* HOW TO BUY */
.how-to-buy-bubbles {
	position: relative;
	padding-left: 0;
}

.how-to-buy-bubbles li .step-number {
	position: absolute;
	top: 25px;
	left: 20px;
	height: 30px;
	margin-right: 50px;
}

.how-to-buy-bubbles li.step-four .step-text {
	margin-bottom: 10px;
}

.how-to-buy-bubbles li.step-four .king-image {
	max-width: 100%;
	height: auto;
	margin-top: 10px;
	align-self: center;
	margin-left: -90px;
}

.king-image {
	width: 550px;
}

.how-to-buy-bubbles li {
	position: relative;
	display: block;
	text-align: center;
	font-size: 1.5em;
	line-height: 1.8;
	color: #d8d8d8;
	padding: 20px 20px 20px 80px;
	margin-bottom: 10px;
	background-color: rgba(255, 255, 255, 0.1);
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-radius: 40px;
	transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out;
}

.how-to-buy-bubbles li .link {
	display: inline;
	cursor: pointer;
	color: #cdb128;
	text-decoration: none;
	transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.how-to-buy-bubbles li .link:hover {
	color: #ffffff;
	text-shadow: 0 0 10px #824cde, 0 0 20px #824cde;
	transform: scale(1.05);
	border-color: #ffffff;
}

.how-to-buy-bubbles .inline-icon {
	width: 25px;
	height: auto;
	margin-bottom: 5px;
	vertical-align: middle;
}

#phantom {
	width: 32px;
	height: auto;
	margin-bottom: 6px;
}
.or-text {
	text-align: center;
	font-weight: bold;
	font-size: 1.6rem;
	margin-bottom: 10px;
	display: block; 
  }
@keyframes textGlow {
	0% {
		text-shadow: 0 0 1px #aa79ff, 0 0 2px #884dff,
			0 0 5px #772aff;
	}
	100% {
		text-shadow: 0 0 1px #aa79ff,  0 0 2px #884dff,
			0 0 5px #772aff; 
	}
}

.copyable-text {
	cursor: pointer;
	color: #fb6161;
	transition: color 0.3s ease, text-shadow 0.3s ease;
}

.copyable-text:hover {
	color: #ffffff;
	text-shadow: 0 0 10px #fb6161, 0 0 20px #fb6161;
}

.king-gif {
	max-width: 35%;
	height: auto;
	border-radius: 10px;
}
.emoji-stack {
	position: relative;
	line-height: 0.6; 
	font-size: 2em; 
}


.crown {
	position: relative;
	z-index: 2; 
}


.world {
	position: relative;
	z-index: 1; 
}

/* Mobile adjustments */
@media only screen and (max-width: 768px) {
	.tile {
		min-height: auto;
		padding: 20px;
		width: 100%;
		max-width: 100%;
		border-radius: 65px;
	}
	.info-container {
		padding: 25px;
		flex-direction: column;
		align-items: center;
	}
	.info-container > section {
		width: calc(100dvw - 50px);
		max-width: calc(100dvw - 50px);
	}
	.tile h1 {
		font-size: 1.8em;
	}
	.statement-text {
		font-size: 0.9em;
	}
	.or-text {
		font-size: 1.2rem;
	  }
	.list-bubbles li {
		font-size: 0.7em;
		line-height: 1.4;
	}
	.how-to-buy-bubbles li .step-number {
		position: absolute;
		left: 15px;
		top: 20px;
		height: 20px;
		width: auto;
	}
	.how-to-buy-bubbles li {
		padding: 23px;
	}
	#phantom {
		width: 15px;
		margin-bottom: 4px;
	}
	#sol {
		width: 13px;
		margin-bottom: 4px;
	}
	.how-to-buy-bubbles li.step-four .king-image {
		margin-right: -50px;
		max-width: 100%;
	}
	.king-image {
		width: 250px;
	}

	.king-gif {
		max-width: 80%;
	}
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
}
