.header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	position: relative;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
	padding-top: 20px;
	background-color: rgb(0, 0, 0);
	width: 100vw;
	height: auto;
	overflow: hidden;
}

/* .ca-text {
	cursor: pointer;
	color: #ffffff;
	transition: color 0.3s ease, text-shadow 0.3s ease;
	font-size: 1.6em;
	border: 2px solid rgba(173, 146, 11, 0.532);
	border-radius: 40px;
	padding: 10px;
	margin-top: 50px;
	transition: color 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out;
	margin-left: -20px;
	margin-right: -20px;
}

.ca-text:hover {
	color: #aa79ff;
	text-shadow: 0 0 10px #b8952c, 0 0 20px #ffca2c;
	transform: scale(1.05);
	background-color: rgba(0, 0, 0, 0.3);
	border-color: #ffffff;
} */

.horizontal-box {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	height: 70px;
	gap: 750px;
	z-index: 2000;
	width: 100%;
	position: relative;
	margin-left: -110px;
}

.banner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1000;
	width: 1200px;
	height: 500px;
	margin-bottom: 20px;
	box-sizing: border-box;
}

.crown-gif {
	margin-top: 25px;
	margin-right: -80px;
	width: 70%;
	max-width: 250px;
	height: auto;
}
.banner-img {
	margin-top: 50px;
	width: 3500px;
	height: 800px;
	/* margin-top: 50px; */
}

.banner-mobile {
	display: none;
}

.gif-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 200%);
	width: 100dvw;
	color: #aa79ff;
	font-size: 4em;
	font-weight: bold;
	text-shadow: 0 0 3px #aa79ff, 0 0 6px #884dff, 0 0 10px #772aff;
	text-align: center;
	z-index: 1;
}

.menu-toggle {
	font-size: 2.7em;
	cursor: pointer;
	color: #cdb128;
	transition: color 0.3s ease, transform 0.3s ease;
	margin-top: 20px;
}

.menu-toggle:hover {
	color: #aa79ff;
	transform: scale(1.1) rotate(90deg);
}

.nav-links {
	position: fixed;
	top: 50%;
	left: 0;
	width: 250px;
	background: linear-gradient(90deg, #1f1f1f, #3a3f44);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transform: translate(-100%, -50%);
	transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
	z-index: 1200;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
	border-radius: 15px;
}

.nav-links.open {
	transform: translate(0, -50%);
}

.nav-links ul {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
}

.nav-links li {
	margin: 20px 0;
	width: 100%;
	text-align: center;
	animation: fadeIn 0.5s ease-in-out forwards;
	opacity: 0;
}

.nav-links li:nth-child(1) {
	animation-delay: 0.1s;
}
.nav-links li:nth-child(2) {
	animation-delay: 0.2s;
}
.nav-links li:nth-child(3) {
	animation-delay: 0.3s;
}
.nav-links li:nth-child(4) {
	animation-delay: 0.4s;
}

@keyframes fadeIn {
	to {
		opacity: 1;
	}
}

.nav-links a {
	color: white;
	text-decoration: none;
	font-size: 1.5em;
	transition: color 0.3s, text-shadow 0.3s;
}

.nav-links a:hover {
	color: #cdb128;
	text-shadow: 0 0 10px #824cde, 0 0 20px #824cde;
}

@media only screen and (max-width: 768px) {
	.header {
		height: 240px;
	}
	.horizontal-box {
		margin-right: 0px;
		height: 30px;
		margin-top: -20px;
		justify-content: space-between;
		width: 100%;
		gap: 50px;
	}
	.ca-text {
		display: none;
	}
	.logo-img {
		width: 195px;
		height: 160px;
		margin-top: 20px;
		margin-left: -30px;
	}
	.banner-img {
		width: 1350px;
		height: auto;
		margin-bottom: 100px;
	}
	/* .banner-mobile {
		display: block;
		width: 400px;
		height: auto;
	} */
	.banner-container {
		width: 1200px;
		height: 250px;
	}
	.crown-gif {
		margin-top: 80px;
		width: 100%;
		max-width: 200px;
		height: auto;
	}
	.gif-text {
		font-size: 1.5em;
		text-shadow: none;
	}
	.menu-toggle {
		font-size: 2em;
		margin-left: auto;
		margin-right: -40px;
		margin-top: 80px;
	}

	.nav-links a {
		font-size: 1.2em;
	}
}
